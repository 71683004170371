@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

html {
  height: 100%;
}

body {
  height: 100%;
  margin: 0;
  overflow: hidden;

  background: linear-gradient(23deg, #6529c5, #0005ff, #6529c5);
  background-size: 600% 600%;
  animation: flowingGradientBG 10s ease infinite;

  font-family: 'Helvetica', sans-serif;
  color: #f0f0f0;
}

#app {
  height: 100%;
  background: radial-gradient(ellipse at top left, #e6646560, transparent 30%),
            radial-gradient(ellipse at bottom right, #dba90460, transparent 30%);
  animation: fadingBG 10s ease infinite;
  background-position: center;
}

@keyframes flowingGradientBG {
  0%{background-position:50% 0%}
  50%{background-position:51% 100%}
  100%{background-position:50% 0%}
}

@keyframes fadingBG {
  0%{background-size: 130% 130%}
  50%{background-size: 100% 100%}
  100%{background-size: 130% 130%}
}
