.home {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 100px;
  position: relative;

  background: radial-gradient(#16c1ff80, #0005ff10);
}

.fixed {
  position: absolute;
  opacity: 0;
}

@media all and (max-width: 600px) {
  /* Mobile */
  .coming {
    font-size: 56px;
  }
}
